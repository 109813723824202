import { STATIC_ASSETS_CDN_URL } from './consts';

const hostPrefix = `${STATIC_ASSETS_CDN_URL}/images`;

export const images = {
  expertProfilePageHeader: `${hostPrefix}/page-headers/expertProfilePageHeader.jpeg`,
  expertIntegrationsPageHeader: `${hostPrefix}/page-headers/expertIntegrationsPageHeader.jpeg`,
  expertPayoutPageHeader: `${hostPrefix}/page-headers/expertPayoutPageHeader.png`,
  monitoringHomeMock: `${hostPrefix}/theme/monitoring-home-preview.jpg`,
  monitoringOverviewMock: `${hostPrefix}/theme/monitoring-overview-preview.jpg`,
  monitoringFacebookMock: `${hostPrefix}/theme/monitoring-facebook-preview.jpg`,
  monitoringGoogleAdsMock: `${hostPrefix}/theme/monitoring-google-preview.jpg`,
  integration_step: `${hostPrefix}/theme/integration_step.jpg`,
  marketer_user_registration: `${hostPrefix}/theme/marketer_user_registration.jpg`,
  businessRegistration: `${hostPrefix}/theme/business-registration.jpg`,
  agencyProfilePic: `${hostPrefix}/theme/agency-profile-pic.jpg`,
  freelancerProfilePic: `${hostPrefix}/theme/freelancer-profile-pic.jpg`,
  paymentsAndAccountsPic: `${hostPrefix}/theme/marketer-manual-integ.png`,
  skillsVettingPicFB: `${hostPrefix}/theme/marketer-vetting-integ-facebook.png`,
  skillsVettingPicGoogle: `${hostPrefix}/theme/marketer-vetting-integ-google.png`,
  vettingRightSideBar: `${hostPrefix}/theme/vetting-rightsidebar-bg.png`,
  marketersSummaryImage: `${hostPrefix}/theme/marketers-summary-image.jpg`,
  marketersCallSchedulingImage: `${hostPrefix}/theme/schedule-a-call.jpg`,
  mayplerProfilePic: `${hostPrefix}/theme/maypler_profile_pic.png`,
  noImageAvailable: `${hostPrefix}/theme/noImageAvailable.gif`,
  mayplerAvatar: `${hostPrefix}/avatars/RakefetAvatarOrange.png`,
  mayplerDanielAvatar: `${hostPrefix}/avatars/daniel_avatar.png`,
  mayplerTomerLevyAvatar: `${hostPrefix}/avatars/tomer_levi_avatar.jpg`,
  mayplerRakefetAvatar: `${hostPrefix}/avatars/rakafetAvatar.jpg`,
  maypleMobileAvatar: `${hostPrefix}/avatars/danielMobileAvatar.png`,
  mayplerAvatarMichal: `${hostPrefix}/avatars/michalAvatar.jpg`,
  MICHAL_MEIR: `${hostPrefix}/avatars/MICHAL_MEIR.jpg`,
  ASAF_TISCHLER: `${hostPrefix}/avatars/ASAF_TISCHLER.jpg`,
  TOMER_LEVY: `${hostPrefix}/avatars/TOMER_LEVY.jpg`,
  HADARA_ALOOK: `${hostPrefix}/avatars/HADARA_ALOOK.jpg`,
  SHIRI_BERZACK: `${hostPrefix}/avatars/SHIRI_BERZACK.jpg`,
  JULIA_MENIS: `${hostPrefix}/avatars/JULIA_MENIS.jpg`,
  MYRIAM_HERMANO: `${hostPrefix}/avatars/MYRIAM_HERMANO.png`,
  bmSilhouette: `${hostPrefix}/avatars/bm_silhouette.png`,
  userSilhouette: `${hostPrefix}/avatars/user_silhouette.jpg`,
  maypleAvatar01: `${hostPrefix}/avatars/maypleAvatar01.png`,
  maypleAvatar02: `${hostPrefix}/avatars/maypleAvatar02.png`,
  maypleAvatar03: `${hostPrefix}/avatars/maypleAvatar03.png`,
  maypleAvatar04: `${hostPrefix}/avatars/maypleAvatar04.png`,
  maypleAvatar05: `${hostPrefix}/avatars/maypleAvatar05.png`,
  maypleAvatar06: `${hostPrefix}/avatars/maypleAvatar06.png`,
  facebookLogSquare: `${hostPrefix}/facebook/facebook-log-square1-128.png`,
  metaBadge: `${hostPrefix}/facebook/metaBadge.png`,
  googleAdwordsLogo: `${hostPrefix}/google/google-adwords-logo-small-128.png`,
  adwordsCreateBmTutorial: `${hostPrefix}/google/adwords_create_bm_tutorial.png`,
  warmUpAppV1: `${hostPrefix}/warmup/warmupapp-v1.png`,
  warmUpAppV2: `${hostPrefix}/warmup/warmupapp-v2.png`,
  warmUpIllustrationBg: `${hostPrefix}/warmup/BG-illsutration.svg`,
  warmUpIllustrationIPad: `${hostPrefix}/warmup/ipad-illustration.png`,
  warmUpIllustrationIPad25: `${hostPrefix}/warmup/ipad-illustration-025.png`,
  warmUpIllustrationIPad50: `${hostPrefix}/warmup/ipad-illustration-05.png`,
  warmUpIllustrationIPadX2: `${hostPrefix}/warmup/ipad-illustration-x2.png`,
  warmUpPerson: `${hostPrefix}/warmup/happy_customer.jpg`,
  warmUpQuotes: `${hostPrefix}/warmup/quotes.svg`,
  // LandingPage
  compTableMobile: `${hostPrefix}/landingPage/thirdToEightSections/compTableMobile.jpg`,
  bestStrategy: `${hostPrefix}/landingPage/thirdToEightSections/bestStrategy.jpg`,
  betterResults: `${hostPrefix}/landingPage/thirdToEightSections/betterResults.jpg`,
  mainGoal: `${hostPrefix}/landingPage/thirdToEightSections/mainGoal.jpg`,
  monitored: `${hostPrefix}/landingPage/thirdToEightSections/monitored.jpg`,
  perfectTeam: `${hostPrefix}/landingPage/thirdToEightSections/perfectTeam.jpg`,
  p2pMarketing: `${hostPrefix}/landingPage/p2pMarketing.png`,
  digitalMarketingAgencies2019: `${hostPrefix}/landingPage/firstSection/ \
  Digital_Marketing_Agencies_2019_resized.png`,
  // carouselStepper
  andréAzambuja: `${hostPrefix}/carousel/AndréAzambuja.jpg`,
  brunoPereira: `${hostPrefix}/carousel/BrunoPereira.jpg`,
  leilaBorgesGuimarães: `${hostPrefix}/carousel/LeilaBorgesGuimarães.jpg`,
  // project onboarding
  projectLaunchedDialog: `${hostPrefix}/project-onboarding/projectLaunchedDialog.png`,
  assessmentCardStars: `${hostPrefix}/project-onboarding/assessmentCardStars.jpg`,
  // TODO: switch all vetting pic to smaller ones because of bg

  // no-match articles
  article1: `${hostPrefix}/articles/CRO.png`,
  article2: `${hostPrefix}/articles/plan.png`,
  article3: `${hostPrefix}/articles/eCom.png`,
  hostThePro: `${hostPrefix}/articles/hostThePro.png`,
  dharianaLozanoAvatar: `${hostPrefix}/avatars/dharianaLozanoAvatar.png`,
  chaseDimondAvatar: `${hostPrefix}/avatars/chaseDimondAvatar.png`,
};

export const getMaypleRandomAvatar = () => images[`maypleAvatar0${Math.ceil(Math.random() * 6)}`];
